import React from 'react';

if (process.env.CLIENT) {
  require('./Icons.scss'); // eslint-disable-line global-require
}

const ClockIcon = () => (
  <svg className="icon icon--clock" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <polygon points="17 15.4 17 6 15 6 15 16.6 23.8 21.7 24.8 19.9 17 15.4" />
    <path d="M16,4A12,12,0,1,1,4,16,12,12,0,0,1,16,4m0-4A16,16,0,1,0,32,16,16,16,0,0,0,16,0Z" />
  </svg>
);

const ArrowIcon = () => (
  <svg className="icon icon--arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <polygon points="29,16 3,0 3,7.2 17.6,16 3,24.8 3,32 29,16" />
  </svg>
);

const CloseIcon = () => (
  <svg className="icon icon--close" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M32 3.5L28.5 0 16 12.5 3.5 0 0 3.5 12.5 16 0 28.5 3.5 32 16 19.5 28.5 32l3.5-3.5L19.5 16z" />
  </svg>
);

const PreviousIcon = () => (
  <span className="icon icon--previous" aria-label="Previous">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <polygon points="0,16 26,0 26,7.2 11.4,16 26,24.8 26,32" />
    </svg>
  </span>
);

const NextIcon = () => (
  <span className="icon icon--next" aria-label="Next">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <polygon points="26,16 0,0 0,7.2 14.6,16 0,24.8 0,32" />
    </svg>
  </span>
);

const DownIcon = () => (
  <span className="icon icon--down">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>down</title>
      <polygon points="16,29 32,3 24.8,3 16,18.3 7.2,3 0,3 16,29" />
    </svg>
  </span>
);

const UpIcon = () => (
  <span className="icon icon--up">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <title>up</title>
      <polygon points="16,3 0,29 7.2,29 16,13.7 24.8,29 32,29 16,3" />
    </svg>
  </span>
);

const TickIcon = () => (
  <span className="icon icon--tick">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 32">
      <polygon points="12.9,28.9 31.9,6.3 28.1,3.1 12.3,21.9 3.3,14.4 0.1,18.2 12.9,28.9" />
    </svg>
  </span>
);

const SearchIcon = () => (
  <span className="icon icon--search">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M32,28.5l-8.2-8.2a13,13,0,1,0-3.5,3.5L28.5,32ZM6.6,19.4A9,9,0,1,1,13,22,8.9,8.9,0,0,1,6.6,19.4Z" />
    </svg>
  </span>
);

const LoadingIcon = () => (
  <span className="icon icon--loading">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <path d="M29.8,8l-3.5,2A12,12,0,1,1,16,4V0A16,16,0,1,0,29.8,8Z" />
    </svg>
  </span>
);

export {
  ClockIcon,
  ArrowIcon,
  CloseIcon,
  PreviousIcon,
  NextIcon,
  DownIcon,
  UpIcon,
  TickIcon,
  SearchIcon,
  LoadingIcon,
};
