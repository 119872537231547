import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';

const MetaProperties = ({ title, description, imageUrl, imageWidth, imageHeight, canonical }) => (
  <Helmet>
    <title>{title}</title>
    <meta property="og:title" content={title} />
    <meta property="og:site_name" content="BBC Food" />
    <meta name="twitter:site" content="@BBCFood" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:card" content="summary_large_image" />
    {description && <meta name="description" content={description} />}
    {description && <meta property="og:description" content={description} />}
    {description && <meta name="twitter:description" content={description} />}
    {imageUrl && <meta property="og:image" content={imageUrl} />}
    {imageUrl && <meta property="og:image:secure_url" content={imageUrl} />}
    {imageUrl && <meta property="og:image:type" content="image/jpeg" />}
    {imageUrl && imageWidth && <meta property="og:image:width" content={imageWidth} />}
    {imageUrl && imageHeight && <meta property="og:image:height" content={imageHeight} />}
    {imageUrl && <meta property="og:image:alt" content={title} />}
    {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    {canonical && <meta property="og:url" content={canonical} />}
    {canonical && <link rel="canonical" href={canonical} />}
  </Helmet>
);

MetaProperties.defaultProps = {
  description: undefined,
  imageUrl: undefined,
  imageWidth: undefined,
  imageHeight: undefined,
  canonical: undefined,
};

MetaProperties.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  canonical: PropTypes.string,
};

export default MetaProperties;
