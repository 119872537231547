import React from 'react';
import PropTypes from 'prop-types';
import { CollectionTitle } from '../../promo/Collection';

if (process.env.CLIENT) {
  // eslint-disable-next-line global-require
  require('./IngredientsList.scss');
}

const IngredientsList = ({ ingredients }) => (
  <div className="ingredients-list__wrap gel-wrap">
    <CollectionTitle className="gel-double-pica" TitleElement="h2">
      Shopping list
    </CollectionTitle>
    {ingredients.map(({ ingredients: locationIngredients, locationTitle }) => (
      <div key={locationTitle} className="gel-layout ingredient-list-location">
        <div className="gel-layout__item">
          <CollectionTitle className="gel-great-primer ingredients-list__title" TitleElement="h3">
            {locationTitle}
          </CollectionTitle>
        </div>

        {locationIngredients.map((ingredient, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} className="gel-layout__item ingredients-list-item">
            <input className="ingredients-list-item__checkbox" type="checkbox" />

            <span
              className="ingredients-list-item__text"
              dangerouslySetInnerHTML={{ __html: ingredient }}
            />
          </div>
        ))}
      </div>
    ))}
  </div>
);

IngredientsList.propTypes = {
  ingredients: PropTypes.arrayOf(
    PropTypes.shape({
      locationTitle: PropTypes.string.isRequired,
      ingredients: PropTypes.arrayOf(PropTypes.string),
    }).isRequired
  ).isRequired,
};

export default IngredientsList;
