import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PageHeader from '../../generic/PageHeader';
import IconButton from '../../generic/IconButton';
import ContentSeparator from '../../generic/ContentSeparator';
import IngredientsList from './IngredientsList';
import RecipeList from './RecipeList';
import { promoShape } from '../../promo/shapes';
import MetaProperties from '../../generic/MetaProperties';

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
}

export const Page = ({ baseURL, description, id, ingredients, recipes, title }) => (
  <>
    <MetaProperties
      title={`${title} shopping list`}
      description={description}
      canonical={`${baseURL}/food/menus/${id}`}
    />
    <PageHeader className="menu-shopping-list__header" title={`${title} - Shopping list`}>
      <IconButton
        className="menu-shopping-list__print-button"
        iconClassName="menu-shopping-list__print-button__icon"
        onClick={() => window.print()}
        text="Print shopping list"
      />
    </PageHeader>
    <ContentSeparator />
    <RecipeList recipes={recipes} />
    <ContentSeparator />
    <IngredientsList ingredients={ingredients} />
    <div className="shopping-list-icon__wrap gel-wrap">
      <div className="gel-layout">
        <div className="gel-layout__item gel-1/1 gel-7/12@m gel-1/3@xl gel-1/4@xxl">
          <IconButton
            className="menu-shopping-list__back-button"
            Element="a"
            href={`/food/menus/${id}`}
            iconClassName="menu-shopping-list__back-button__icon"
            text="Back to menu"
          />
        </div>
      </div>
    </div>
  </>
);

Page.defaultProps = {
  recipes: [],
  ingredients: [],
};

Page.propTypes = {
  baseURL: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  ingredients: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
  title: PropTypes.string.isRequired,
};

export default connect(state => ({
  baseURL: state.pageReducer.baseURL,
  description: state.collectionReducer.description,
  id: state.collectionReducer.id,
  ingredients: state.collectionReducer.ingredients,
  recipes: state.collectionReducer.recipes,
  title: state.collectionReducer.title,
}))(Page);
