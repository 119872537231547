import PropTypes from 'prop-types';

// eslint-disable-next-line import/prefer-default-export
export const promoShape = {
  breakpoints: PropTypes.shape({
    content: PropTypes.string,
    image: PropTypes.string,
    promo: PropTypes.string,
  }),
  collectionId: PropTypes.string,
  collectionTitle: PropTypes.string,
  duration: PropTypes.string,
  id: PropTypes.string.isRequired,
  imageId: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  playIconClass: PropTypes.string,
  synopsis: PropTypes.string,
  synopsisClassName: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  servingsText: PropTypes.string,
  prepTime: PropTypes.string,
  cookingTime: PropTypes.string,
  episodeNumber: PropTypes.number,
  seriesNumber: PropTypes.number,
  standard: PropTypes.bool,
};

export const heroShape = {
  ...promoShape,
  chef: PropTypes.string,
  footer: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    totalCount: PropTypes.number,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  subtitle: PropTypes.string,
  occasionSubtitle: PropTypes.string,
};
