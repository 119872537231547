import React from 'react';

if (process.env.CLIENT) {
  require('./ContentSeparator.scss'); // eslint-disable-line global-require
}

const ContentSeparator = () => (
  <div className="gel-wrap content-separator__container">
    <div className="content-separator" />
  </div>
);

export default ContentSeparator;
