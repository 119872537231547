import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

if (process.env.CLIENT) {
  require('./IconButton.scss'); // eslint-disable-line global-require
}

const IconButton = ({ className, Element, iconClassName, onClick, text, ...rest }) => (
  <Element
    className={classNames('gel-long-primer-bold icon-button', className)}
    onClick={onClick}
    {...rest}
  >
    <span className={classNames('icon-button__icon', iconClassName)} />
    {text}
  </Element>
);

IconButton.defaultProps = {
  className: '',
  Element: 'button',
  iconClassName: '',
  onClick: undefined,
};

IconButton.propTypes = {
  className: PropTypes.string,
  Element: PropTypes.node,
  iconClassName: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
};

export default IconButton;
