import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

if (process.env.CLIENT) {
  require('./PageHeader.scss'); // eslint-disable-line global-require
}

const Header2 = ({ className, title, anchor }) => (
  <PageHeader
    className={className}
    title={title}
    Tag="h2"
    gelClassName="gel-greater-primer"
    anchor={anchor}
  />
);

Header2.defaultProps = {
  className: '',
  anchor: 'featured-content',
};

Header2.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  anchor: PropTypes.string,
};

const PageHeader = ({ children, className, description, Tag, title, gelClassName, anchor }) => (
  <div className={classnames('page-header', 'gel-wrap', className)}>
    <div className="gel-layout">
      <div id={anchor} className="gel-layout__item page-header__item">
        <Tag className={classnames(gelClassName, 'page-header__title')}>{title}</Tag>
        {description && <div className="page-header__description">{description}</div>}
        {children}
      </div>
    </div>
  </div>
);

PageHeader.defaultProps = {
  children: null,
  className: '',
  description: undefined,
  Tag: 'h1',
  gelClassName: 'gel-trafalgar',
  anchor: 'featured-content',
};

PageHeader.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.node,
  Tag: PropTypes.node,
  title: PropTypes.string.isRequired,
  gelClassName: PropTypes.string,
  anchor: PropTypes.string,
};

export { PageHeader as default, Header2 };
