import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ClockIcon, ArrowIcon } from '../generic/Icons';

if (process.env.CLIENT) {
  require('./Collection.scss'); // eslint-disable-line global-require
}

const Collection = ({
  children,
  collectionClassName,
  containerClassName,
  description,
  id,
  titleClassName,
  title,
  TitleElement,
}) => (
  <div
    id={id}
    className={classNames('gel-wrap', 'promo-collection__container', containerClassName)}
  >
    {title && (
      <CollectionTitle className={titleClassName} TitleElement={TitleElement}>
        {title}
      </CollectionTitle>
    )}
    {description && <CollectionDescription description={description} />}
    <CollectionPromos className={classNames(['promo-collection', collectionClassName])}>
      {children}
    </CollectionPromos>
  </div>
);

Collection.defaultProps = {
  collectionClassName: undefined,
  containerClassName: undefined,
  description: undefined,
  id: undefined,
  titleClassName: undefined,
  title: undefined,
};

Collection.propTypes = {
  children: PropTypes.node.isRequired,
  collectionClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  titleClassName: PropTypes.string,
  title: PropTypes.string,
  TitleElement: PropTypes.string.isRequired,
};

const CollectionDescription = ({ description }) => (
  <div
    className="promo-collection__description gel-body-copy gel-1/1 gel-10/12@m"
    dangerouslySetInnerHTML={{ __html: description }}
  />
);

CollectionDescription.propTypes = {
  description: PropTypes.string.isRequired,
};

const CollectionPromos = ({ children, className }) => (
  <div className={classNames('gel-layout', 'gel-layout--equal', className)}>{children}</div>
);

CollectionPromos.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CollectionPromos.defaultProps = {
  className: undefined,
};

const CollectionTitle = ({ className, TitleElement, children }) => {
  const defaultTypographyClass = TitleElement === 'h1' ? 'gel-trafalgar' : 'gel-double-pica';
  return (
    <TitleElement
      className={classNames(['promo-collection__title', className || defaultTypographyClass])}
    >
      {children}
    </TitleElement>
  );
};

CollectionTitle.defaultProps = {
  className: undefined,
};

CollectionTitle.propTypes = {
  className: PropTypes.string,
  TitleElement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

const CollectionTitleLink = ({ className, TitleElement, children, href }) => (
  <CollectionTitle className={className} TitleElement={TitleElement}>
    <a href={href} className="promo-collection__title--link">
      {children}
      <ArrowIcon />
    </a>
  </CollectionTitle>
);

CollectionTitleLink.defaultProps = {
  className: undefined,
};

CollectionTitleLink.propTypes = {
  className: PropTypes.string,
  TitleElement: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
};

const CollectionDate = ({ children }) => (
  <div className="promo-collection__date gel-body-copy gel-1/1 gel-10/12@m">
    <ClockIcon /> {children}
  </div>
);

CollectionDate.propTypes = {
  children: PropTypes.node.isRequired,
};

export {
  Collection as default,
  CollectionPromos,
  CollectionTitle,
  CollectionTitleLink,
  CollectionDescription,
  CollectionDate,
};
