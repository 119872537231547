import React from 'react';
import PropTypes from 'prop-types';
import { CollectionTitle } from '../../promo/Collection';
import { promoShape } from '../../promo/shapes';

if (process.env.CLIENT) {
  require('./RecipeList.scss'); // eslint-disable-line global-require
}

const RecipeList = ({ recipes }) => (
  <div className="recipe-list-collection-title__wrap gel-wrap">
    <CollectionTitle
      className="gel-double-pica menu-shopping-list__recipe-list__title"
      TitleElement="h2"
    >
      This shopping list includes the following recipes
    </CollectionTitle>
    <div className="gel-layout">
      {recipes.map(({ id, title, type }) => (
        <a
          key={id}
          className="gel-layout__item gel-1/1 gel-1/2@m menu-shopping-list__recipe-list-item"
          href={`/food/recipes/${id}`}
        >
          <div className="gel-great-primer-bold menu-shopping-list__recipe-list-item__title">
            {title}
          </div>
          <div className="gel-long-primer promo__type menu-shopping-list__recipe-list-item__type">
            {type || ' '}
          </div>
        </a>
      ))}
    </div>
  </div>
);

RecipeList.defaultProps = {
  recipes: [],
};

RecipeList.propTypes = {
  recipes: PropTypes.arrayOf(PropTypes.shape(promoShape)),
};

export default RecipeList;
